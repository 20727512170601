export interface mailStructure {
    mId: string,
    unread: boolean,
    subject: string,
    content: string
}

export interface threadStructure {
    last_Message: string,
    action_Required: number,
    is_Closed: number,
    is_read: number | boolean,
    pos: number,
    job_Id: number,
    division_Id: number,
    posting_Id: number,
    job_Name: string,
    req_Name: string,
    poster_Id: string,
    position: string,
    division_Name: string,
    city: string,
    state: string,
    country: string,
    job_Status: string,
    created_date: string,
    updated_date: string,
    order_date: string,
    site_Name: string,
    recruiter_Id: string
}

export interface messageStructure {
    posting_Id: number,
    message_Id: number,
    added_date: string,
    note: string,
    action: string,
    is_Closed: number,
    is_read: number,
    recruiter_Id: number,
    division_Id: number,
    action_Required: number,
    attachment: string,
}

export interface mailStoreStructure {
    [key: number]: messageStructure[]
}

export enum FOLDER_TYPES {
    INBOX = "INBOX",
    DELETED = "DELETED",
    OTHER = "OTHER FOLDER"
}