import React, { lazy, Suspense } from 'react';
import Modal from 'react-modal';
import './Root.scss';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEnvelopeOpen, faPaperclip } from '@fortawesome/free-solid-svg-icons';


import Header from './Header/Header';
//import MailList from './MailList/MailList';
import MailBody from './MailBody/MailBody';

const MailListComponent = lazy(() => import('./MailList/MailList'));

// Bootstrap New Message Modal
Modal.setAppElement('#root');

// ROOT COMPONENT
const Root: React.FC = props => {

    // const [modalIsOpen, setModalIsOpen] = React.useState(false);

    // const openModal = () => {
    //     setModalIsOpen(true);
    // }

    // const closeModal = () => {
    //     setModalIsOpen(false);
    // }

    return (
        <div>
            {/* <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="new-message-modal"
                overlayClassName="new-message-modal-overlay"
            >
                <nav className="wrapper">
                    <FontAwesomeIcon size="2x" icon={faEnvelopeOpen}></FontAwesomeIcon>
                    <span className="titleStyles">Message Center</span>
                </nav>
                <main>
                    <div className="replyWrapper">
                        <div className="breadcrumbs">
                            <span className="link" onClick={closeModal}>All Messages  </span>
                            <span>/  New Message</span>
                        </div>
                        <input className="toTextarea" placeholder='To: Enter a name or email'/>
                        <span className="replyHeader">Message</span>
                        <textarea className="replyTextarea"></textarea>
                        <div className="replyFooter">
                            <label className="attachment">
                                <input type="file" accept="image/png, image/jpeg"></input>
                                <FontAwesomeIcon size="1x" icon={faPaperclip} className="deleteIconStyles"></FontAwesomeIcon>
                                <span className="smallText">Attachment</span>
                            </label>
                            <div>
                                <span className="link" onClick={closeModal}>Cancel</span>
                                <button className="replyButton" onClick={closeModal}>Send</button>
                            </div>
                        </div>
                    </div>
                </main>
            </Modal> */}
                {/* <button className="hack" onClick={openModal}>message</button> */}
                <div className="container my-1" id='jt-app'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card root-wrapper" id='jt-app-card'>
                                <section className="root-header">
                                    <Header></Header>
                                </section>
                                <section className="root-maillist">
                                    {/* <MailList></MailList> */}
                                    <Suspense fallback={<div>isLoading...</div>}>
                                        <MailListComponent />
                                    </Suspense>
                                </section>
                                <section className="root-mailbody">
                                    <MailBody></MailBody>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default Root;
