import { observable, action, makeObservable, toJS, computed } from 'mobx';
import { messageStructure, mailStoreStructure,threadStructure, FOLDER_TYPES } from './../Utils/models';
import {localStorageSync} from './MailProvider'

export class MailStore {

    @observable.shallow inboxData: threadStructure[];
    @observable.shallow mailStore: mailStoreStructure;
    @observable.shallow messageThreadData: messageStructure[];
    @observable.shallow deletedData: threadStructure[];
    @observable.shallow otherData: threadStructure[];
    @observable.shallow searchResults: threadStructure[];
    @observable selectedID: number;
    @observable divisionIds: string[];
    @observable selectedFolder: FOLDER_TYPES;
    @observable flagIds: Array<number>;
    @observable flagFilter: boolean;
    @observable jtToken: string;
    @observable doneFetching: boolean;
    baseURL: string;
    apiConfig: object;

    constructor() {
        makeObservable(this);
        this.inboxData = [];
        this.mailStore = {};
        this.messageThreadData = [];
        this.deletedData = [];
        this.searchResults = [];
        this.otherData = [];
        this.selectedID = null;
        this.divisionIds = [];
        this.selectedFolder = FOLDER_TYPES.INBOX;
        this.flagIds = [];
        this.flagFilter = false;
        this.jtToken = null;
        this.doneFetching = false;

        this.baseURL = process.env.REACT_APP_PLATFORM_API;
        localStorageSync(this);
        this.apiConfig = {
            headers: { Authorization: `Bearer ${this.jtToken}` }
        };
    }

    // SET ID
    @action
    setID = (id: number) => {
        this.selectedID = id;
    }

    // SET SELECTED FOLDER FOLDER
    @action
    setFolder = (folderName: FOLDER_TYPES) => {
        this.selectedFolder = folderName;
        this.selectedID = null;
        this.flagFilter = false;
    }

    // MARK AS READ & UNREAD
    @action
    readTheMail = (posting_Id: number, toggle: boolean = false,) => {
        let filteredDataClone = this.getMailData.map(el => {
            if (el.posting_Id === posting_Id) {
                return {
                    ...el, is_read: true
                }
            }
            return el;
        })
        this.syncTheData(filteredDataClone);
    }

    // SET THREAD DATA
    @action
    setThreadData = (data: messageStructure[]) => {
        this.mailStore[this.selectedID] = data;
    }

    // GET THREAD DATA
    @action
    getThreadData = (posting_Id: number) => {
        return this.mailStore[posting_Id] || [];
        // return toJS(this.messageThreadData).filter(el => el.posting_Id === posting_Id);
    }

    @action
    setSearchResults = (data: threadStructure[]) => {
        this.searchResults = data;
    }

    @action
    getSearchResults = () => {
        return toJS(this.searchResults);
    }

    @action
    getDivisionDropdownData = () => {
        return toJS(this.inboxData).map(el => {
            return String(el.division_Id);
        })
    }

    // DELETE MAIL
    @action
    deleteTheMail = (posting_Id: number) => {

        if (posting_Id === this.selectedID) {
            this.selectedID = null
        }

        // INSERTING THE MAIL INTO DELETED FOLDER
        let deletedMail = this.getMailData.find(el => el.posting_Id === posting_Id);
        if (deletedMail) {
            this.deletedData.push(deletedMail)
        }

        // REMOVING THE MAIL FRON THE RESPECTIVE FOLDER
        let filteredData = this.getMailData.filter(el => el.posting_Id !== posting_Id);
        this.syncTheData(filteredData);

    }

    // SET THE FLAGS
    @action
    setFlags = (posting_Id: number) => {
        var flagIdsClone = new Set(this.flagIds);
        flagIdsClone.has(posting_Id) ? flagIdsClone.delete(posting_Id) : flagIdsClone.add(posting_Id);
        this.flagIds = Array.from(flagIdsClone);
    }

    // TOGGLE FLAG FILTER
    @action
    toggleFlagFilter = (val: boolean) => {
        this.flagFilter = val;
        this.selectedID = this.flagIds.includes(this.selectedID) ? this.selectedID : null;
    }

    // COMMON SYNCING THE FILES INTO THE RESPECTIVE FOLDERS
    @action
    syncTheData(data: threadStructure[]) {
        switch (this.selectedFolder) {
            case FOLDER_TYPES.INBOX:
                this.inboxData = data;
                break;

            case FOLDER_TYPES.DELETED:
                this.deletedData = data;
                break;

            case FOLDER_TYPES.OTHER:
                this.otherData = data;
                break;

            default:
                break;
        }
    }

    @action
    syncTheDataPagination = async (data: threadStructure[]) => {
        var mergeData = this.inboxData.concat(data)
        this.syncTheData(mergeData.filter((v,i,a)=>a.findIndex(v2=>(v2.posting_Id===v.posting_Id))===i));
    }

    // ******** COMPUTED DATA ***********
    // SET INBOX DATA
    @computed
    get getMailData() {
        switch (this.selectedFolder) {

            case FOLDER_TYPES.INBOX:
                return toJS(this.inboxData);

            case FOLDER_TYPES.DELETED:
                return toJS(this.deletedData);

            case FOLDER_TYPES.OTHER:
                return toJS(this.otherData);

            default:
                return toJS(this.inboxData);
        }
    }

    @computed
    get getFilteredData() {
        // return this.getMailData.filter(el => this.flagIds.includes(el.mId))
        return this.getMailData;
    }

    @computed
    get searchResultsList() {
        return toJS(this.searchResults);
    }

    @computed
    get getUnreadCount() {
        let inboxUnreadCount = toJS(this.inboxData).filter(el => !el.is_read).length;
        let deletedUnreadCount = toJS(this.deletedData).filter(el => !el.is_read).length;
        let otherUnreadCount = toJS(this.otherData).filter(el => !el.is_read).length;
        return [inboxUnreadCount, deletedUnreadCount, otherUnreadCount]
    }

    @computed
    get isDoneFetching() {
        return this.doneFetching;
    }

}

