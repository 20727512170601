import React from 'react';
import { MailStore } from './MailStore';
import { MailContext } from './MailContext';
import { autorun } from 'mobx';
import { toast } from 'react-toastify';
import axios from 'axios';

// AUTO RUN - SYNCING DATA TO LOCAL STORAGE
export const localStorageSync = async (_this: MailStore) => {
    let firstRun = true;
    let jtToken;

    const baseURL = `${process.env.REACT_APP_PLATFORM_API}?increment=${process.env.REACT_APP_DEFAUL_INCREMENT}&startpos=1`;
    // will run on change
    autorun(async () => {
        // get the data
        if (firstRun) {
            (window as any).addEventListener('userinfo:jt:navbar', async (e) => {
                jtToken = e.detail.jtToken;
                try {
                    const { data } = await axios(baseURL, {
                        headers: {'Authorization': `Bearer ${jtToken}`}
                    });
    
                    if (data.length) {
                        const divisions = data.map(el => String(el.division_Id));
                        _this.divisionIds = divisions.filter((el, i) => divisions.indexOf(el) === i)
                        _this.inboxData = data;
                    }
                    _this.doneFetching = true;
                }
                catch (err) {
                    _this.inboxData = [];
                    _this.doneFetching = true;
                    
                    console.log('error getting threads', err);
                    toast.error('Error getting threads.', {
                        position: "top-center",
                        theme: "colored",
                        closeOnClick: true,
                        autoClose: 3000,
                        pauseOnHover: true,
                    });
                }
            });
        }
    });
    
    firstRun = false
}

const mailStoreObj = /*window['mehulDebug'] = */ new MailStore()

export const MailProvider: React.FC<React.PropsWithChildren<{}>> = props => {
    return <MailContext.Provider value={mailStoreObj}>
        {props.children}
    </MailContext.Provider>
}