import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { MailProvider } from "./Store/MailProvider";

const subDomain = window.location.hostname.split(".")[0];

if (subDomain === "messagecenter") {
  const tagManagerArgs = {
    gtmId: "GTM-N79XCN9",
  };

  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <React.StrictMode>
    <MailProvider>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        closeOnClick
        hideProgressBar={false}
        pauseOnHover
        theme="colored"
      />
      <App />
    </MailProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
