import React, {useCallback, useContext, useEffect, useState} from 'react';
import { MailContext } from './../../Store/MailContext';
import classes from './Header.module.scss';
import icon from './../../Assets/Images/icon.png';
import Cookies from 'js-cookie';
import axios from 'axios';
const Axios = require('axios').default;

const Header: React.FC = () => {
    const mailData = useContext(MailContext);
    const { setSearchResults } = mailData;

    const [divisions, setDivisions] = useState([]);
    const [searchData, setSearchData] = useState('');
    const [selectedDivision, setSelectedDivision] = useState('');
    const jtToken = Cookies.get('jtToken');

    const baseUrl = process.env.REACT_APP_PLATFORM_API

    const searchUrl = `${baseUrl}/search`
    const apiConfig = {
        headers: { Authorization: `Bearer ${jtToken}` }
    };

    const getDivisions = async () => {
        
        try {
            const { data } = await axios(baseUrl, {
                headers: {'Authorization': `Bearer ${jtToken}`}
            });
            if (data.length !== 0) {
                const ids = data.map(el => String(el.division_Id));
                setDivisions(ids.filter((el, i) => ids.indexOf(el) === i));
            }
            else {
                setDivisions([0]);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const search = useCallback(() => {
        const payload = {
            "division_id": selectedDivision || divisions[0] || "956013",
            "keyword": searchData,
            "is_Closed" : "0",
            "action_required" : "0"
        };
        Axios.post(
            searchUrl,
            payload,
            apiConfig
        ).then(res => {
            // console.log('search response', res);
            setSearchResults(res.data);
        }).catch(console.log);
    }, [searchUrl, apiConfig, searchData, selectedDivision, divisions, setSearchResults]);

    const clearSearch = useCallback(() => {
        setSearchData('');
        setSearchResults([]);
    }, [setSearchResults, setSearchData]);

    const handleDivisionChange = useCallback((e) => {
        // console.log('division change', e.target.value);
        setSelectedDivision(e.target.value);
    }, []);

    const generateSearchBar = useCallback((): JSX.Element => {
        return (
            <div>
                <select
                    className={classes.searchInput}
                    placeholder='Division'
                    value={selectedDivision}
                    onChange={handleDivisionChange}>
                    {divisions.map((el, i) => {
                        return <option key={i} value={el}>{el}</option>
                    })}
                </select>
                <input className={classes.searchInput} value={searchData} onChange={e=> setSearchData(e.target.value)} placeholder='Search' />
                <button className={classes.searchButton} onClick={search}>Search</button>
                <br />
                <span onClick={clearSearch} className={classes.clearText}>Clear Search</span>

            </div>
        )
    }, [search, divisions, handleDivisionChange, searchData, selectedDivision, clearSearch]);

    // useEffect(() => {
    //     getDivisions();
    // }, []);

    return (
        <div className={classes.wrapper}>
            <div className="d-flex align-items-center">
                <img src={icon} alt="icon" />
                <h1 className={'h5 ' + classes.appName}>Message Center</h1>
            </div> 
            {/* {
                divisions.length ? generateSearchBar() : null
            } */}
        </div>
    );
};

export default Header;