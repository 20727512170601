import React, { useMemo, useContext, useCallback, useState, useEffect } from 'react';
import classes from './MailBody.module.scss';
// import envelope from './../../Assets/Images/envelope.svg';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { MailContext } from './../../Store/MailContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import axios from 'axios';

// MAIL BODY COMPONENT HANDLES DISPALYING, REPLYING, AND DELETING MESSAGES
const MailBody: React.FC = observer(props => {

    // DATA FROM APP STORE
    const mailData = useContext(MailContext);
    const { getThreadData, setThreadData, getMailData, selectedID, flagFilter, getFilteredData, deleteTheMail, isDoneFetching } = mailData;
    const metaJSON = useMemo(() => {
        return (flagFilter ? getFilteredData : getMailData)!.find(el => el.posting_Id === selectedID);
    }, [getMailData, selectedID, getFilteredData, flagFilter]);

    // LOCAL COMPONENT STATE
    const [jtToken, setJtToken] = useState('');
    const [inputData, setInputData] = useState('');
    const [currentThreadId, setCurrentThreadId] = useState<number>(0);
    const [threadMessages, setThreadMessages] = useState<any[]>([]);
    const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isGetDone, setIsGetDone] = useState(false);

    const baseUrl = process.env.REACT_APP_PLATFORM_API;
    const messagesUrl = `${baseUrl}/thread`;
    const sendAPI = `${baseUrl}/send`;
    const attachmentAPI = `${baseUrl}/upload`;
    const deleteUrl = `${baseUrl}/delete`;

    // FETCHING THREAD MESSAGES DATA
    const getMessages = useCallback(async (postingId) => {
        //setIsGetDone(false);
        if (postingId === null) {
            return;
        }
        try {
            const { data } = await axios(`${messagesUrl}/${postingId}`, {
                headers: {'Authorization': `Bearer ${jtToken}`}
            });
            if (data) {
                setIsGetDone(true);
                setThreadData(data);
                setCurrentThreadId(postingId);
                setThreadMessages(data);
            }
        } catch (err) {
            console.log(err);
        }
    }, [messagesUrl, setThreadData, setThreadMessages, setCurrentThreadId, setIsGetDone, jtToken]);

    // SEND ATTACHMENT
    const sendAttachment = useCallback(async (noteId) => {
        const formData = new FormData();
        formData.append('attachment', selectedFile);

        try {
            await axios.post(`${attachmentAPI}/${noteId}`, 
                formData, 
                {
                    headers: {
                        'Authorization': `Bearer ${jtToken}`,
                        'Content-type': "multipart/form-data"
                    }
                }
            );

            setInputData('');
            setSelectedFile(null);
            setIsFilePicked(false);
            await getMessages(selectedID);
        } catch (err) {
            console.log(err);
        }
    }, [attachmentAPI, selectedFile, selectedID, getMessages, jtToken]);

    // SEND MESSAGE
    const sendMsg = useCallback(async (payload) => {
        try {
            const { data} = await axios.post(sendAPI, payload, {
                headers: {'Authorization': `Bearer ${jtToken}`}
            });

            if (data) {
                console.log('sendMsg', data);
                const note_Id = data.note_Id;
                if (isFilePicked) {
                    await sendAttachment(note_Id)
                }
                setInputData('');
                await getMessages(selectedID);
                setIsGetDone(true);
                toast.success('Message sent.', {
                    position: "top-center",
                    theme: "colored",
                    closeOnClick: true,
                    autoClose: 3000,
                    pauseOnHover: true,
                });
            }
        }
        catch (err) {
            toast.error('Failed sending message, please try again later.', {
                position: "top-center",
                theme: "colored",
                closeOnClick: true,
                autoClose: 3000,
                pauseOnHover: true,
            });
        }
    }, [sendAPI, selectedID, isFilePicked, sendAttachment, getMessages, jtToken]);

    // DELETE MESSAGE
    const deleteMsg = useCallback(async (postingId) => {
        if (window.confirm("Are you sure you want to delete this thread?") === true) {
            try {
                await axios.get(`${deleteUrl}/${postingId}`, {
                    headers: {'Authorization': `Bearer ${jtToken}`}
                })
                
                deleteTheMail(postingId);
                toast.success('Thread deleted Successfully.', {
                    position: "top-center",
                    theme: "colored",
                    closeOnClick: true,
                    autoClose: 3000,
                    pauseOnHover: true,
                });
            }
            catch (err) {
                toast.error('Error deleting thread, please try again later.', {
                    position: "top-center",
                    theme: "colored",
                    closeOnClick: true,
                    autoClose: 3000,
                    pauseOnHover: true,
                });
            }
        }
    }, [deleteUrl, deleteTheMail, jtToken]);

    // GETTING THE THREAD MESSAGES DATA FROM STORE
    if (selectedID && (!getThreadData(selectedID).length || currentThreadId !== selectedID)) {
        setTimeout(async () => {
            await getMessages(selectedID);
            setIsGetDone(true);
        }, 7000);
    }

    useEffect(() => {
        (window as any).addEventListener('userinfo:jt:navbar', (e) => {
            setJtToken(e.detail.jtToken);
        })
    }, [])

    useEffect(() => {
        setIsGetDone(false);
    }, [selectedID])

    const handleFile = useCallback((event) => {
        const fileTypes: string[] = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png', 'application/msword', 'application/pdf', 'text/plain'];
        const file = event.target.files[0];
        console.log(file);
        if (file && fileTypes.includes(file.type)) {
            setSelectedFile(event.target.files[0]);
            setIsFilePicked(true);
            event.target.value = '';
        }
        else if (!fileTypes.includes(file.type)) {
            toast.error('File type not supported.', {
                position: "top-center",
                theme: "colored",
                closeOnClick: true,
                autoClose: 3000,
                pauseOnHover: true,
            });
        }
    }, [setSelectedFile, setIsFilePicked]);

    // CREATING THE THREAD MESSAGES UI FRAGMENT
    const messagesContent = useMemo<JSX.Element | null>(() => {
        if (threadMessages.length) {
            return (
                <div className={classes.scrollable}>
                    {threadMessages.map((el, index) => {
                        return (
                            <div key={index} className={classes.message}>
                                <div className={classes.messageHeader}>
                                    <div className={classes.messageHeaderItem}>
                                        <span className={classes.bold}>From: </span>
                                        <span>{ el.recruiter_Id === 0 ? `JobTarget Posting Support` : el.recruiter_Id }</span>
                                    </div>
                                    <div className={classes.messageHeaderItem}>
                                        <span className={classes.bold}>Date: </span>
                                        <span>{el.added_date}</span>
                                    </div>
                                    <div className={classes.messageHeaderItem}>
                                        <span className={classes.bold}>Subject: </span>
                                        <span>Posting Alert</span>
                                    </div>
                                </div>
                                <div className={classes.MessageBody}>
                                    <p>{el.note}</p>
                                    <p>
                                        {el.attachment ? <a href={el.attachment} target="_blank" rel="noopener noreferrer">{el.attachment}</a> : null}
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        }

    }, [threadMessages]);

    // CREATING THE MAIL BODY FRAGMENT WITH META
    const mailContent = useMemo<JSX.Element | null>(() => {
        const jobStatus = threadMessages.length
            ? threadMessages[0].status
            : null;
        const showMessageInput = (jobStatus === 'active' ||
            jobStatus === 'pending_account' ||
            jobStatus === 'account_services' ||
            jobStatus === 'automated_error') ? true : false; 

        if (flagFilter ? getFilteredData.length : getMailData.length) {
            if (!selectedID) {
                return (
                    <section className={classes.noMailContentWrapper}>
                        {/* <img src={envelope} alt="no img" /> */}
                        <div className='text-body-color font-weight-normal'>Select a message to read</div>
                    </section>
                )
            }
            else if (!isGetDone) {
                return (
                    <section className={classes.noMailContentWrapper}>
                        {/* <img src={envelope} alt="no img" /> */}
                        <div className='spinner-border text-primary' role='status'></div>
                        <div className='text-body-color font-weight-normal'>Loading Thread...</div>
                    </section>
                )
            }
            else if (metaJSON && isGetDone) {
                return (
                    <div className={classes.mainBody}>
                        <header className={classes.profileHeader}>
                            <div className={classes.outerFlex}>
                                <FontAwesomeIcon size="3x" icon={faUserCircle} className={classes.profileIconStyles} onClick={() => getMessages(metaJSON?.posting_Id)}></FontAwesomeIcon>
                                <div className={classes.innerFlex}>
                                    {metaJSON.action_Required ? <span className={classes.actionRequired}>ACTION REQUIRED</span> : null}
                                    <span>Job ID: {metaJSON?.job_Id} - {metaJSON?.updated_date}</span>
                                    <span>{metaJSON?.position} - {metaJSON?.site_Name} &nbsp; <FontAwesomeIcon icon={faTrashAlt} size="lg" className={[classes.iconStyles, classes.deleteIconStyles, classes.right].join(" ")} onClick={() => deleteMsg(metaJSON.posting_Id)}></FontAwesomeIcon></span>
                                    <span>Requisition ID: {metaJSON?.req_Name}</span>
                                    <span>Location: {metaJSON?.city}, {metaJSON?.state}</span>
                                    <span>Posting ID: {metaJSON?.posting_Id} | Division: {metaJSON.division_Name}</span>
                                </div>
                            </div>
                            {/* {(selectedFolder !== FOLDER_TYPES.DELETED) && <FontAwesomeIcon size="sm" icon={faTrashAlt} className={classes.deleteIconStyles} onClick={deleteClickHandler.bind(null, metaJSON?.posting_Id)}></FontAwesomeIcon>} */}
                        </header>


                        <main className={classes.scrollable}>
                            {
                                messagesContent? messagesContent : <div dangerouslySetInnerHTML={{ __html: metaJSON.last_Message }} className={classes.dangerousDiv}></div>
                            }
                            {
                                (metaJSON.is_Closed || !showMessageInput) ? <p className={classes.actionRequired}>This message is now closed, please contact <a href='mailto:support@jobtarget.com'>support@jobtarget.com</a> if you have questions.</p> : (<div className={classes.replyWrapper}>
                                    <span className={classes.replyHeader}>Message</span>
                                    <textarea className={classes.replyTextarea} value={inputData} onChange={e=> setInputData(e.target.value)}></textarea>
                                    <div className={classes.replyFooter}>
                                        <label className={classes.attachment}>
                                            <input type="file" id="attachment" name="attachment" accept="image/png, image/jpeg, .pdf, .doc, .docx, .txt" onChange={handleFile}></input>
                                            <FontAwesomeIcon size="1x" icon={faPaperclip} className={classes.deleteIconStyles}></FontAwesomeIcon>
                                            <span className={classes.smallText}>Attachment {isFilePicked ? ` - ${selectedFile.name || ''}` : ''}</span><br />
                                            <span className={classes.smallText}>.doc, .docx, .pdf, .png, .jpg, .txt</span>
                                        </label>
                                        <button className={classes.replyButton} onClick={() => {
                                            sendMsg({
                                                'divisionid': `${metaJSON.division_Id}`,
                                                'postingid': `${metaJSON.posting_Id}`,
                                                'notes': inputData
                                            });
                                        }}>Send</button>
                                    </div>
                                </div>)
                            }
                        </main>
                    </div>
                )
            }
        }
        else if (isDoneFetching) {
            return (
                <section className={classes.noMailContentWrapper}>
                    {/* <img src={envelope} alt="no img" /> */}
                    <div className='text-body-color font-weight-normal'>You have no messages.</div>
                </section>
            )
        }
        else if (!isGetDone) {
            return (
                <section className={classes.noMailContentWrapper}>
                    {/* <img src={envelope} alt="no img" /> */}
                    <div className='spinner-border text-primary' role='status'></div>
                    <div className='text-body-color font-weight-normal'>Loading Messages...</div>
                </section>
            )
        }
        return null;
    }, [getMailData, selectedID, metaJSON, threadMessages, getFilteredData, flagFilter, inputData, sendMsg, getMessages, messagesContent, selectedFile, handleFile, isFilePicked, deleteMsg, isDoneFetching, isGetDone]);

    return (
        <div className={classes.wrapper}>
            {mailContent}
        </div>
    )
})

export default MailBody;